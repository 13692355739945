import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { Sort, SortDirection } from '@angular/material/sort';
import { PagingParams } from '../services/query-params/models';

export abstract class PaginationController<T = unknown> {
  public pageIndex = 0;
  public pageSize = 10;
  public length = 0;
  public sortActive = '';
  public sortDirection: SortDirection = '';
  public dataSource = new MatTableDataSource<T>();
  public search = '';
  public filters: Record<string, unknown> = {};
  public loading = false;

  protected abstract paginate(paging: PagingParams): Observable<Pagination<T>>;

  private resetProps(): void {
    this.pageIndex = 0;
    this.pageSize = 10;
    this.length = 0;
    this.sortActive = '';
    this.sortDirection = '';
    this.dataSource.data = [];
    this.search = '';
    this.filters = {};
  }

  public get pageNumber(): number {
    return this.pageIndex + 1;
  }

  public loadData(): void {
    this.loading = true;
    this.paginate({
      ...this.filters,
      key: this.sortActive,
      order: this.sortDirection,
      page: this.pageNumber,
      search: this.search,
    }).subscribe({
      next: ({ data, total }) => {
        this.dataSource.data = data;
        this.length = total;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  public page(ev: PageEvent): void {
    this.pageIndex = ev.pageIndex;
    this.pageSize = ev.pageSize;
    this.loadData();
  }

  public onSort(ev: Sort): void {
    this.sortActive = ev.active;
    this.sortDirection = ev.direction;
    this.loadData();
  }

  public onSearch(search: string): void {
    this.resetProps();
    this.search = search;
    this.loadData();
  }

  public refreshData(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.resetProps();
    this.loadData();
  }

  public filter(filters: Record<string, unknown>): void {
    this.resetProps();
    this.filters = filters;
    this.loadData();
  }
}
