export interface MaxFileSizeExceededValidationErrorProps {
  fileSize: number;
  maxSize: number;
}

export class MaxFileSizeExceededValidationError
  implements MaxFileSizeExceededValidationErrorProps
{
  public maxSizeLabel = '';

  constructor(public fileSize: number, public maxSize: number) {
    this.maxSizeLabel = this.getUnitConvertionOf('maxSize', 'mb');
  }

  public getUnitConvertionOf(
    key: 'fileSize' | 'maxSize',
    to: 'mb' | 'kb' | 'gb',
  ): string {
    const unit = {
      gb: 1073741824,
      mb: 1048576,
      kb: 1024,
    };
    const result = this[key] / unit[to];
    return `${result.toFixed(2)} ${to.toUpperCase()}`;
  }
}
