import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DndDirective } from './dnd/dnd.directive';
import { ResponsiveTableDirective } from './responsive-table/responsive-table.directive';
import { ControlErrorsDirective } from './control-errors/control-errors.directive';
import { TecNoDataRowModule } from './tec-no-data-row/tec-no-data-row.module';

@NgModule({
  declarations: [
    DndDirective,
    ResponsiveTableDirective,
    ControlErrorsDirective,
  ],
  imports: [CommonModule],
  exports: [
    DndDirective,
    ResponsiveTableDirective,
    ControlErrorsDirective,
    TecNoDataRowModule,
  ],
})
export class DirectivesModule {}
