<header class="grid" [ngClass]="'justify-content-' + justifyContent">
  <div
    *ngIf="showSearcher; else noSearchTemplate"
    class="col-12 lg:col-fixed lg:w-auto"
  >
    <mat-form-field appearance="fill" class="w-full">
      <mat-label translate="tec-table-toolbar.search-label"></mat-label>
      <input
        [(ngModel)]="searchValue"
        (keyup.enter)="search.emit(searchValue)"
        [ngModelOptions]="{ standalone: true }"
        type="text"
        matInput
      />
      <!-- Search button -->
      <button
        *ngIf="searchValue?.length"
        (click)="clearSearch()"
        mat-icon-button
        type="button"
        matSuffix
      >
        <mat-icon>close</mat-icon>
      </button>
      <button
        (click)="search.emit(searchValue)"
        mat-icon-button
        matSuffix
        [matTooltip]="'tec-table-toolbar.buttons.search' | translate"
        type="button"
      >
        <mat-icon>search</mat-icon>
      </button>
      <ng-container matSuffix *ngTemplateOutlet="toolsTemplate"></ng-container>
    </mat-form-field>
  </div>
</header>

<ng-template #noSearchTemplate>
  <mat-card [ngClass]="'mat-elevation-z0'">
    <mat-card-content class="p-1">
      <ng-container *ngTemplateOutlet="toolsTemplate"></ng-container>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #toolsTemplate>
  <!-- Filters button -->
  <button
    *ngIf="showFiltersButton"
    [disabled]="loading"
    [matMenuTriggerFor]="filtersMenu"
    [matTooltip]="'tec-table-toolbar.buttons.filter' | translate"
    mat-icon-button
    type="button"
  >
    <mat-icon>filter_alt</mat-icon>
  </button>
  <!-- Download button -->
  <button
    *ngIf="showDownloadButton"
    (click)="download.emit($event)"
    [disabled]="loading"
    mat-icon-button
    type="button"
    [matTooltip]="'tec-table-toolbar.buttons.download' | translate"
  >
    <mat-icon>download</mat-icon>
  </button>
  <!-- Add button -->
  <button
    *ngIf="showAddButton"
    [disabled]="loading"
    (click)="add.emit($event)"
    [matTooltip]="'tec-table-toolbar.buttons.add' | translate"
    mat-icon-button
    type="button"
  >
    <mat-icon>add</mat-icon>
  </button>
  <!-- CUstom menu button -->
  <button
    [disabled]="loading"
    [matMenuTriggerFor]="customMenu"
    [matTooltip]="'tec-table-toolbar.buttons.custom-menu-label' | translate"
    *ngIf="customMenu"
    mat-icon-button
    type="button"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-template>

<mat-menu #filtersMenu="matMenu">
  <button (click)="filterBy.emit($event)" mat-menu-item>
    <mat-icon>open_in_new</mat-icon
    >{{ 'tec-table-toolbar.buttons.filter-by' | translate }}
  </button>
  <button (click)="removeFilters.emit($event)" mat-menu-item>
    <mat-icon>filter_alt_off</mat-icon
    >{{ 'tec-table-toolbar.buttons.remove-filters' | translate }}
  </button>
</mat-menu>
