import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { TecDialogFormActionsComponent } from './tec-dialog-form-actions.component';

@NgModule({
  declarations: [TecDialogFormActionsComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, TranslateModule],
  exports: [TecDialogFormActionsComponent],
})
export class TecDialogFormActionsModule {}
