import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastService } from '../services/toast/toast.service';
import { AuthActions } from 'src/app/auth/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  public handleError(error: Error | HttpErrorResponse): void {
    const store = this.injector.get(Store);
    const toastService = this.injector.get(ToastService);
    const translateService = this.injector.get(TranslateService);
    if (error instanceof HttpErrorResponse) {
      let message = '';
      message = error.message;
      message = this.getHttpErrorResponseMessage(error, translateService);
      if (error.status === HttpStatusCode.Unauthorized)
        store.dispatch(AuthActions.logout());
      toastService.showError(message, undefined, {
        duration: 10000,
      });
    }
    console.error(`[${GlobalErrorHandler.name}]`, error);
  }

  private getHttpErrorResponseMessage(
    error: HttpErrorResponse,
    translate: TranslateService,
  ): string {
    const defaultMessage = translate.instant('http-error-unknown-error');
    let message = defaultMessage;
    const messagesByErrorStatusCode: Map<number, string> = new Map([
      [
        HttpStatusCode.Unauthorized,
        translate.instant('http-error-unauthorized'),
      ],
      [HttpStatusCode.Forbidden, translate.instant('http-error-forbidden')],
      [HttpStatusCode.NotFound, translate.instant('http-error-not-found')],
      [
        HttpStatusCode.RequestTimeout,
        translate.instant('http-error-request-timeout'),
      ],
      [HttpStatusCode.BadRequest, translate.instant('http-error-bad-request')],
      [
        HttpStatusCode.UnprocessableEntity,
        translate.instant('http-error-unprocessable-entity'),
      ],
      [
        HttpStatusCode.TooManyRequests,
        translate.instant('http-error-too-many-request'),
      ],
    ]);

    if (messagesByErrorStatusCode.has(error.status))
      message = messagesByErrorStatusCode.get(error.status);

    if (error.error?.error_controlado) message = error.error.error_controlado;

    return message;
  }
}
