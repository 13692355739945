import { EventEmitter, HostBinding, HostListener } from '@angular/core';
import { Directive, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]',
})
export class DndDirective {
  @HostBinding('class.fileover')
  public fileOver = false;
  @Output()
  public fileDropped = new EventEmitter<unknown>();

  @HostListener('dragover', ['$event']) public onDragOver(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer?.files;
    const readerFile = new FileReader();

    if (files && files.length) {
      const file = files[0];

      readerFile.readAsDataURL(file);
      const name = file.name.replace(/\.[^/.]+$/, '');

      readerFile.onload = () => {
        const strResult = readerFile.result as string;
        const tipo = file.type;
        const size = file.size / 1024 / 1024;

        this.fileDropped.emit({ imagen: strResult, name, size, tipo });
      };
    }
  }
}
