import { Component, Inject } from '@angular/core';
import { TecConfirmDialogConfig } from './models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './tec-confirm-dialog.component.html',
})
export class TecConfirmDialogComponent {
  public config: TecConfirmDialogConfig;
  private defaultConfig: TecConfirmDialogConfig;

  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) data?: TecConfirmDialogConfig,
  ) {
    this.defaultConfig = {
      title: this.translateService.instant('confirm-dialog.title'),
      cancelLabel: this.translateService.instant('confirm-dialog.cancel'),
      confirmLabel: this.translateService.instant('confirm-dialog.confirm'),
      description: this.translateService.instant('confirm-dialog.description'),
    };
    this.config = {
      ...this.defaultConfig,
      ...(data || {}),
    };
  }
}
