import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { ToastService } from '../toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomClipboardService {
  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  public copy(value: string): void {
    const copied = this.clipboard.copy(value);
    if (copied) {
      this.toastService.showInfo(this.translate.instant('clipboard.copied'));
    } else {
      this.toastService.showError(
        this.translate.instant('clipboard.error-trying-to-copy'),
      );
    }
  }
}
