import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  EMAIL_REGEX,
  ONLY_LETTERS_AND_NUMBERS,
  ONLY_NUMERIC_REGEX,
  REGEX_ONLY_ALPHABETIC,
} from './format';
import { CustomValidationErrorBuilder, Logger } from 'src/app/core/classes';
import { Nullable } from './types';

export function emailValidator(
  control: AbstractControl,
): Nullable<ValidationErrors> {
  if (!EMAIL_REGEX.test(control.value)) {
    return new CustomValidationErrorBuilder().emailValidationError({
      regex: EMAIL_REGEX,
    });
  }
  return null;
}

export function onlyAlphabeticValidator(
  options = { nullable: false },
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (options.nullable && control.value === null) return null;
    if (!REGEX_ONLY_ALPHABETIC.test(control.value)) {
      return new CustomValidationErrorBuilder().onlyAlphabeticValidationError({
        regex: REGEX_ONLY_ALPHABETIC,
      });
    }
    return null;
  };
}

export function noSpecialCharactersValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) return null;
    if (!ONLY_LETTERS_AND_NUMBERS.test(control.value)) {
      return new CustomValidationErrorBuilder().noSpecialCharactersValidationError(
        { regex: ONLY_LETTERS_AND_NUMBERS },
      );
    }
    return null;
  };
}

export function onlyNumericValidator(
  options = { nullable: false },
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (options.nullable && control.value === null) return null;
    if (!ONLY_NUMERIC_REGEX.test(control.value)) {
      return new CustomValidationErrorBuilder().onlyNumericValitacionError({
        regex: ONLY_NUMERIC_REGEX,
      });
    }
    return null;
  };
}

export function valueMustMatchValidator(
  formControlNames: [string, string, ...string[]],
  customValidationError?: ValidationErrors,
): ValidatorFn {
  return (control: AbstractControl): Nullable<ValidationErrors> => {
    const logger = new Logger(valueMustMatchValidator.name);
    if (!(control instanceof FormGroup)) {
      logger.warn('This validator can only be applied to a FormGroup instance');
      return null;
    }

    const controls: AbstractControl[] = [];
    formControlNames.forEach((n) => {
      const c = control.get(n);
      if (c) {
        controls.push(c);
      } else {
        logger.warn(`Could not get control "${n}"`);
      }
    });

    if (controls.length > 2) {
      logger.warn('This validator requires at least two controls');
      return null;
    }

    const refValue = controls[0].value;

    const validationError =
      customValidationError ||
      new CustomValidationErrorBuilder().valueMustMatchValidationError(
        refValue,
      );

    if (controls.some((c) => c.value !== refValue) && controls[0].touched) {
      controls.forEach((c) => {
        const currentErrors = c.errors;
        c.setErrors(
          currentErrors
            ? { ...currentErrors, ...validationError }
            : validationError,
        );
        c.markAsTouched();
      });
    } else {
      controls.forEach((c) => {
        const currentErrors = c.errors;
        const currentErrorsCopy = { ...(currentErrors || {}) };
        delete currentErrorsCopy[Object.keys(validationError)[0]];
        c.setErrors(
          Object.keys(currentErrorsCopy).length ? currentErrorsCopy : null,
        );
      });
    }
    return controls[0].errors;
  };
}

export function maxSizeValidator(maxSize: number, each = true): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value instanceof FileList) {
      if (each) {
        for (const file of control.value) {
          if (file.size > maxSize) {
            return new CustomValidationErrorBuilder().maxFileSizeExceeded({
              fileSize: file.size,
              maxSize: maxSize,
            });
          }
        }
      } else {
        const fileSizes: number[] = [];
        for (const file of control.value) {
          fileSizes.push(file.size);
        }
        const totalFileSizes = fileSizes.reduce((prev, next) => prev + next, 0);
        if (totalFileSizes > maxSize)
          return new CustomValidationErrorBuilder().maxFileSizeExceeded({
            fileSize: totalFileSizes,
            maxSize: maxSize,
          });
      }
    }
    return null;
  };
}
