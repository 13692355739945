import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ChangePasswordPayload,
  LoginPayload,
  LoginResponse,
  RequestEmailToResetPasswordPayload,
} from '../models';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    Login: props<{ payload: LoginPayload }>(),
    'Login Success': props<{ data: LoginResponse }>(),
    'Login Failure': props<{ error: unknown }>(),
    Logout: emptyProps(),
    'Logout Success': emptyProps(),
    'Logout Failure': emptyProps(),
    'Refresh Token': emptyProps(),
    'Refresh Token Success': props<{ data: LoginResponse }>(),
    'Refresh Token Failure': props<{ error: unknown }>(),
    'Request Smail To Reset Password': props<{
      payload: RequestEmailToResetPasswordPayload;
    }>(),
    'Reset Password': props<{ payload: ChangePasswordPayload }>(),
  },
});
