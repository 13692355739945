import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Nullable } from '../../utils/types';

@Component({
  selector: 'tec-table-toolbar',
  templateUrl: './tec-table-toolbar.component.html',
  styleUrls: ['./tec-table-toolbar.component.scss'],
})
export class TecTableToolbarComponent {
  /**
   * Esta propiedad determina la alineación entre el grupo de botones y el input de filtro global.
   * @default end
   * @public
   */
  @Input()
  public justifyContent: 'end' | 'between' | 'start' = 'end';

  @Input()
  public showDownloadButton = false;

  @Output()
  public download = new EventEmitter<MouseEvent>();

  /**
   * Esta propiedad determina si actualmente está ocurriendo una acción asíncrona. Lo cual bloquea los filtros y el botón Agregar.
   * @public
   */
  @Input()
  public loading = false;

  /**
   * Esta propiedad determina si debe mostrarse o no el boton Agregar.
   * @public
   */
  @Input()
  public showAddButton = true;

  /**
   * Esta propiedad determina el icono que se mostrará dentro del boton Agregar, debe ser una key de Material Icons.
   * {@link https://fonts.google.com/icons}
   * @public
   */
  @Input()
  public addButtonIcon = 'add';

  /**
   * Esta propiedad determina si debe mostrarse o no el botón de filtros avanzados.
   * @public
   */
  @Input()
  public showFiltersButton = true;

  /**
   * Esta propiedad determina el icono que se mostrará dentro del boton de filtros, debe ser una key de Material Icons.
   * {@link https://fonts.google.com/icons}
   * @public
   */
  @Input()
  public filtersButtonIcon = 'filter_alt';

  /**
   * Este evento se dispara al cliquear el botón Agregar de la toolbar.
   * @public
   */
  @Output()
  public add = new EventEmitter<MouseEvent>();

  @Input()
  public searchValue = '';

  @Output()
  public searchValueChange = new EventEmitter<string>();

  @Input()
  public showSearcher = true;

  @Input()
  public customMenu: Nullable<MatMenu> = null;

  @Output()
  public search = new EventEmitter<string>();

  @Output()
  public filterBy = new EventEmitter<MouseEvent>();

  @Output()
  public removeFilters = new EventEmitter<MouseEvent>();

  public clearSearch(): void {
    this.searchValue = '';
    this.searchValueChange.emit(this.searchValue);
    this.search.emit(this.searchValue);
  }
}
