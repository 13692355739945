import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';
import { UnprocessableEntityErrors } from './types';

export function keys(enun: Record<string, unknown>): Array<string> {
  const keys = Object.keys(enun);
  return keys.slice(keys.length / 2);
}

export function addError(
  control: AbstractControl,
  error: Record<string, unknown>,
): void {
  control.setErrors(control.errors ? { ...control.errors, ...error } : error);
}

export function catchUnprocessableEntityErrors(
  err: HttpErrorResponse,
): UnprocessableEntityErrors | undefined {
  if (
    err.status === HttpStatusCode.UnprocessableEntity &&
    'errors' in err.error &&
    typeof err.error.errors === 'object' &&
    Object.values(err.error.errors).every(
      (v) => Array.isArray(v) && v.every((el) => typeof el === 'string'),
    )
  ) {
    return err.error.errors;
  }

  return undefined;
}
