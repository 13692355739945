import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'tec-lang-selector',
  templateUrl: './tec-lang-selector.component.html',
  styleUrls: ['./tec-lang-selector.component.scss'],
})
export class TecLangSelectorComponent {
  public selectedLang = '';

  @Input()
  public classList = '';

  constructor(private trasnlateService: TranslateService) {
    this.selectedLang = this.trasnlateService.currentLang;
  }

  public get langOptions(): string[] {
    return this.trasnlateService.getLangs();
  }

  public get currentLang(): string {
    return this.trasnlateService.currentLang;
  }

  public setLang(ev: MatSelectChange): void {
    this.trasnlateService.use(ev.value);
  }
}
