<div role="group" (focusin)="onFocusIn()" (focusout)="onFocusOut()">
  <div class="flex align-items-center justify-content-between">
    <input [disabled]="disabled" [value]="myPlaceholder" readonly matInput />
    <button
      (click)="fileInput.click()"
      [disabled]="fileControl.disabled"
      mat-icon-button
      type="button"
    >
      <mat-icon>attach_file</mat-icon>
    </button>
    <input
      (change)="onFileInputChange(fileInput.files)"
      [multiple]="multiple"
      [accept]="accept"
      #fileInput
      hidden
      type="file"
    />
  </div>
</div>
