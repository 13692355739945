import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TecLoadingComponent } from './tec-loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@NgModule({
  declarations: [TecLoadingComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [TecLoadingComponent],
})
export class TecLoadingModule {}
