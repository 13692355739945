/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, Optional, Self } from '@angular/core';
import {
  NgControl,
  AbstractControlDirective,
  ControlValueAccessor,
  FormControl,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'tec-file-input',
  templateUrl: './tec-file-input.component.html',
  styles: [],
  providers: [
    { provide: MatFormFieldControl, useExisting: TecFileInputComponent },
  ],
})
export class TecFileInputComponent
  implements MatFormFieldControl<FileList | null>, ControlValueAccessor
{
  // Value
  @Input()
  public get value(): FileList | null {
    return this.fileControl.value;
  }
  public set value(fileList: FileList | null) {
    this.fileControl.setValue(fileList);
    this.onChange(fileList);
    this.stateChanges.next();
  }
  public fileControl = new FormControl();

  public stateChanges = new Subject<void>();

  // Id
  public static nextId = 0;
  @HostBinding()
  public id = `tec-file-input-${TecFileInputComponent.nextId++}`;

  // Placeholder
  @Input()
  public get placeholder() {
    return this._placeholder;
  }
  public set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder = '';

  public focused = false;
  public empty = false;
  public touched = false;

  @HostBinding('class.floating')
  public shouldLabelFloat = true;

  // Required
  @Input()
  public get required(): boolean {
    return this._required;
  }
  public set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  // Disabled
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    // eslint-disable-next-line no-unused-expressions
    this._disabled ? this.fileControl.disable() : this.fileControl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  // Error state
  public get errorState(): boolean {
    return !!this.ngControl?.invalid && this.touched;
  }

  public controlType?: string | undefined;
  public autofilled?: boolean | undefined;

  public userAriaDescribedBy?: string | undefined;
  public onChange = (_: unknown) => {};
  public onTouched = () => {};

  @Input()
  public multiple = false;

  @Input()
  public accept = '';

  constructor(
    private translate: TranslateService,
    @Optional()
    @Self()
    public ngControl: NgControl | null,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  public setDescribedByIds(ids: string[]): void {
    // throw new Error('Method not implemented.');
  }
  public onContainerClick(event: MouseEvent): void {
    // throw new Error('Method not implemented.');
  }
  public writeValue(obj: any): void {
    this.value = obj;
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  public onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  public onFocusOut(): void {
    this.touched = true;
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  public onFileInputChange(files: FileList | null): void {
    this.value = files?.length ? files : null;
  }

  public get myPlaceholder(): string {
    const controlValue = this.value;
    if (!controlValue || !controlValue?.length) {
      return (
        this.placeholder ||
        this.translate.instant('tec-file-input.default-placeholder')
      );
    } else if (controlValue instanceof FileList) {
      if (controlValue.length === 1) {
        return controlValue[0]?.name;
      } else {
        return this.translate.instant(
          'tec-file-input.multiple-selected-files',
          { count: controlValue.length },
        );
      }
    }
    return '';
  }

  private getBase64(file: File): Promise<string> {
    return new Promise<string>((res, rej) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          res(reader.result);
        } else {
          rej('Could not get Base64!');
        }
      };
      reader.readAsDataURL(file);
      reader.onerror = (err) => rej(err);
    });
  }
}
