import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from './capitalize.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DateAgoPipe } from './date-ago.pipe';

@NgModule({
  declarations: [CapitalizePipe, DateAgoPipe],
  imports: [CommonModule, TranslateModule],
  exports: [CapitalizePipe, DateAgoPipe],
})
export class PipesModule {}
