import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TecFileInputComponent } from './tec-file-input.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { Observable, from } from 'rxjs';

export class MyTranslateLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<Record<string, string>> {
    return from(import(`./i18n/${lang}.json`).then((r) => r.default));
  }
}

@NgModule({
  declarations: [TecFileInputComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: MyTranslateLoader,
      },
      extend: true,
    }),
  ],
  exports: [TecFileInputComponent],
})
export class TecFileInputModule {
  constructor(private translate: TranslateService) {
    if (this.translate.currentLang) {
      const curr = this.translate.currentLang;
      this.translate.currentLang = '';
      this.translate.use(curr);
    }
  }
}
