<h1 mat-dialog-title>{{ config.title }}</h1>
<div *ngIf="config.description" mat-dialog-content>
  <p [innerHTML]="config.description"></p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">
    {{ config.cancelLabel }}
  </button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ config.confirmLabel }}
  </button>
</div>
