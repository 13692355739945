import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, concatMap, first, forkJoin, map, mergeMap } from 'rxjs';
import {
  AuthActions,
  selectIsAuthenticated,
  selectIsFirstLogin,
  selectIsSuperAdmin,
} from 'src/app/auth/store';
import { Logger } from '../../classes';
import { Actions, ofType } from '@ngrx/effects';

export const dashboardGuard = ():
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree> => {
  const logger = new Logger(dashboardGuard.name);
  const router = inject(Router);
  const store = inject(Store);
  const actions = inject(Actions);

  const refreshToken$ = (): Observable<true | UrlTree> => {
    logger.log('Refreshing token...');
    store.dispatch(AuthActions.refreshToken());
    return actions.pipe(
      ofType(AuthActions.refreshTokenSuccess, AuthActions.refreshTokenFailure),
      first(),
      concatMap(() => handleAuthentication$),
    );
  };

  const isAuthenticated$ = store.select(selectIsAuthenticated).pipe(first());
  const handleAuthentication$ = forkJoin([
    isAuthenticated$,
    store.select(selectIsFirstLogin).pipe(first()),
    store.select(selectIsSuperAdmin).pipe(first()),
  ]).pipe(
    map(([isAuth, isFirstLogin, isSuperAdmin]) => {
      if (!isAuth) {
        logger.info(`Redirecting to login...`);
        return router.createUrlTree(['/auth/login']);
      }
      if (isAuth && isFirstLogin && !isSuperAdmin) {
        logger.info(`Redirecting to first-login...`);
        return router.createUrlTree(['/auth/first-login']);
      }
      return true;
    }),
  );

  return isAuthenticated$.pipe(
    mergeMap((isAuth) => (isAuth ? handleAuthentication$ : refreshToken$())),
  );
};
