import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { Nullable } from '../../utils/types';

@Component({
  selector: 'tec-page-header',
  templateUrl: './tec-page-header.component.html',
  styleUrls: ['./tec-page-header.component.scss'],
})
export class TecPageHeaderComponent {
  @Input()
  public title = '';

  @Input()
  public showGoBack = false;

  @Input()
  public backTo = '..';

  @ContentChild('rightContent')
  public rightContent: Nullable<TemplateRef<unknown>> = null;
}
