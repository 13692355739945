import { ValidationErrors } from '@angular/forms';
import {
  MaxFileSizeExceededValidationError,
  MaxFileSizeExceededValidationErrorProps,
} from './max-file-size-exceeded-validation-error.class';
import { PasswordMustMatchValidationError } from './passwords-must-match-validation-error-builder.class';
import {
  NoSpecialCharactersValidationError,
  NoSpecialCharactersValidationErrorProps,
} from './no-special-characters-validation-error.class';
import {
  OnlyNumericValidationError,
  OnlyNumericValidationErrorProps,
} from './only-numeric-validation-error.class';
import {
  OnlyAlphabeticValidationError,
  OnlyAlphabeticValidationErrorProps,
} from './only-alphabetic-validation-error.class';
import {
  EmailValidationError,
  EmailValidationErrorProps,
} from './email-validation-error.class';
import {
  UniqueEmailValidationError,
  UniqueEmailValidationErrorProps,
} from './email-already-registered-validation-error.class';
import {
  UniqueDocumentoValidationError,
  UniqueDocumentoValidationErrorProps,
} from './unique-documento-validation-error.class';
import { ValueMustMatchValidationError } from './value-must-match-validation-error.class';

export class CustomValidationErrorBuilder {
  public maxFileSizeExceeded({
    fileSize,
    maxSize,
  }: MaxFileSizeExceededValidationErrorProps): ValidationErrors {
    return {
      [MaxFileSizeExceededValidationError.name]:
        new MaxFileSizeExceededValidationError(fileSize, maxSize),
    };
  }
  public passwordsMustMatch(): ValidationErrors {
    return {
      [PasswordMustMatchValidationError.name]:
        new PasswordMustMatchValidationError(),
    };
  }
  public noSpecialCharactersValidationError({
    regex,
  }: NoSpecialCharactersValidationErrorProps): ValidationErrors {
    return {
      [NoSpecialCharactersValidationError.name]:
        new NoSpecialCharactersValidationError(regex),
    };
  }

  public onlyNumericValitacionError({
    regex,
  }: OnlyNumericValidationErrorProps): ValidationErrors {
    return {
      [OnlyNumericValidationError.name]: new OnlyNumericValidationError(regex),
    };
  }

  public onlyAlphabeticValidationError({
    regex,
  }: OnlyAlphabeticValidationErrorProps): ValidationErrors {
    return {
      [OnlyAlphabeticValidationError.name]: new OnlyAlphabeticValidationError(
        regex,
      ),
    };
  }

  public emailValidationError({
    regex,
  }: EmailValidationErrorProps): ValidationErrors {
    return {
      [EmailValidationError.name]: new EmailValidationError(regex),
    };
  }

  public uniqueEmailValidationError({
    value,
  }: UniqueEmailValidationErrorProps): ValidationErrors {
    return {
      [UniqueEmailValidationError.name]: new UniqueEmailValidationError(value),
    };
  }

  public uniqueDocumentoValidationError({
    value,
  }: UniqueDocumentoValidationErrorProps): ValidationErrors {
    return {
      [UniqueDocumentoValidationError.name]: new UniqueDocumentoValidationError(
        value,
      ),
    };
  }

  public valueMustMatchValidationError(
    referenceValue: unknown,
  ): ValidationErrors {
    return {
      [ValueMustMatchValidationError.name]: new ValueMustMatchValidationError(
        referenceValue,
      ),
    };
  }
}
