import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Observable,
  catchError,
  concatMap,
  finalize,
  map,
  of,
  tap,
} from 'rxjs';
import { Router } from '@angular/router';
import { ToastService } from '@core/services';
import { AuthActions } from './auth.actions';
import { HttpClient, HttpContext } from '@angular/common/http';
import {
  ChangePasswordPayload,
  LoginPayload,
  LoginResponse,
  RequestEmailToResetPasswordPayload,
  RequestEmailToResetPasswordResponse,
} from '../models';
import { environment } from 'src/environments/environment';
import { NO_ERROR_HANDLER } from 'src/app/core/http-contexts';
import { LOCAL_STORAGE_TOKEN_KEY } from '@shared/utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthEffects {
  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      concatMap(({ payload }) =>
        this.login(payload).pipe(
          map((data) => AuthActions.loginSuccess({ data })),
          catchError((error) => of(AuthActions.loginFailure({ error }))),
        ),
      ),
    ),
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      concatMap(() =>
        this.logout().pipe(
          map(() => AuthActions.logoutSuccess()),
          catchError(() => of(AuthActions.logoutFailure())),
        ),
      ),
    ),
  );

  public resetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.resetPassword),
        concatMap(({ payload }) => this.resetPassword(payload)),
      ),
    { dispatch: false },
  );

  public refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      concatMap(() =>
        this.refreshToken().pipe(
          map((data) => AuthActions.refreshTokenSuccess({ data })),
          catchError((error) => of(AuthActions.refreshTokenFailure({ error }))),
        ),
      ),
    ),
  );

  public requestEmailToResetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.requestSmailToResetPassword),
        concatMap(({ payload }) => this.requestEmailToResetPassword(payload)),
      ),
    { dispatch: false },
  );

  private readonly oAuthBaseUrl = environment.apiUrl + '/oauth';

  constructor(
    private actions$: Actions,
    private router: Router,
    private toastService: ToastService,
    private httpClient: HttpClient,
    private translate: TranslateService,
  ) {}

  public login(payload: LoginPayload): Observable<LoginResponse> {
    return this.httpClient
      .post<LoginResponse>(`${this.oAuthBaseUrl}/token`, payload)
      .pipe(
        tap((data) => localStorage.setItem('token', data.access_token)),
        tap(() => this.router.navigate(['/dashboard'])),
      );
  }

  public logout(): Observable<unknown> {
    return this.httpClient
      .post(
        `${this.oAuthBaseUrl}/logout`,
        {},
        { context: new HttpContext().set(NO_ERROR_HANDLER, true) },
      )
      .pipe(
        finalize(() => {
          localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
          this.router.navigate(['/auth/login']);
        }),
      );
  }

  public refreshToken(): Observable<LoginResponse> {
    return this.httpClient
      .post<LoginResponse>(`${this.oAuthBaseUrl}/refresh-token`, {})
      .pipe(
        tap(({ access_token }) =>
          localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, access_token),
        ),
      );
  }

  public resetPassword(payload: ChangePasswordPayload): Observable<unknown> {
    return this.httpClient.post(`${this.oAuthBaseUrl}/reset`, payload).pipe(
      tap(() => {
        this.router.navigate(['/auth/login']);
        this.toastService.showInfo(
          this.translate.instant('notifications.auth-effect.reset-password'),
        );
      }),
    );
  }

  public requestEmailToResetPassword(
    payload: RequestEmailToResetPasswordPayload,
  ): Observable<RequestEmailToResetPasswordResponse> {
    return this.httpClient
      .post<RequestEmailToResetPasswordResponse>(
        `${this.oAuthBaseUrl}/resetpass`,
        payload,
      )
      .pipe(
        tap(() =>
          this.toastService.showInfo(
            this.translate.instant('common.notifications.email-sended'),
          ),
        ),
        tap(() => this.router.navigate(['auth/login'])),
      );
  }
}
