import { Component, Input } from '@angular/core';

@Component({
  selector: 'tec-loading',
  templateUrl: './tec-loading.component.html',
  styleUrls: ['./tec-loading.component.scss'],
})
export class TecLoadingComponent {
  @Input()
  public loading = false;
}
