import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TecLangSelectorComponent } from './tec-lang-selector.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [TecLangSelectorComponent],
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule],
  exports: [TecLangSelectorComponent],
})
export class TecLangSelectorModule {}
