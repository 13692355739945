import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, State } from './auth.reducer';
import { RoleName } from '@shared/utils';
import { Role } from 'src/app/dashboard/modules/users/models';

export const selectAuthState = createFeatureSelector<State>(authFeatureKey);

export const selectAuthUser = createSelector(
  selectAuthState,
  (state: State) => {
    return state.entities[state.ids[0]];
  },
);

export const selectIsAuthenticated = createSelector(
  selectAuthUser,
  (user) => !!user,
);

export const selectIsFirstLogin = createSelector(
  selectAuthUser,
  (user) => !!user?.iniciado,
);

export const selectAuthUserRoles = createSelector(
  selectAuthUser,
  (user) => user?.role,
);

export const selectAuthUserRolesByName = createSelector(
  selectAuthUser,
  (user) => {
    const rolesByName = new Map<string, Role>([]);
    (user?.role || []).forEach((r) => rolesByName.set(r.name, r));
    return rolesByName;
  },
);

export const selectAuthUserPermissions = createSelector(
  selectAuthUser,
  (user) => {
    const permissions = new Set<string>([]);
    const permissionObjs = user?.permissions || [];
    for (const permission of permissionObjs) {
      permissions.add(permission.name);
    }
    return permissions;
  },
);

export const selectAuthUserPermissionGroups = createSelector(
  selectAuthUser,
  (user) => {
    const permissionGroups = new Set<string>([]);
    const permissionObjs = user?.permissions || [];
    for (const permission of permissionObjs) {
      permissionGroups.add(permission.group);
    }
    return permissionGroups;
  },
);

export const selectIsSuperAdmin = createSelector(
  selectAuthUser,
  (user) => !!user?.role?.some((r) => r.name === RoleName.SuperAdministrador),
);
