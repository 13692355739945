export enum EstadoPlanEnum {
  'Deshabilitado',
  'Habilitado',
}

export enum EstadoCivil {
  'Soltero/a',
  'Casado/a',
  'Viudo/a',
}

export enum Genero {
  'M',
  'F',
}

export enum TipoActividadEconomica {
  'Responsable Inscripto',
  'Monotributista',
}

export enum TipoDocumento {
  'DNI',
  'CUIT',
  'CUIL',
}

export enum RoleId {
  SuperAdministrador = 1,
  Administrador = 2,
  Cliente = 3,
}

export enum RoleName {
  SuperAdministrador = 'SUPER ADMINISTRADOR',
  Administrador = 'ADMINISTRADOR',
  Cliente = 'CLIENTE',
}

export enum PermissionGroup {
  Usuarios = 'USUARIOS',
  Permisos = 'PERMISOS',
  Sistema = 'SISTEMA',
}

export enum PermissionName {
  VER_USUARIOS = 'VER USUARIOS',
  EDITAR_USUARIO = 'EDITAR USUARIO',
  CREAR_USUARIO = 'CREAR USUARIO',
  ELIMINAR_USUARIO = 'ELIMINAR USUARIO',
  EDITAR_PERMISOS = 'EDITAR PERMISOS',
}

export enum Language {
  ES = 'es',
  EN = 'en',
}
