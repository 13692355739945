import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TecConfirmDialogComponent } from './tec-confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [TecConfirmDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
  exports: [TecConfirmDialogComponent],
})
export class TecConfirmDialogModule {}
