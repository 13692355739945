import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { User } from 'src/app/dashboard/modules/users/models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { produce } from 'immer';
export const authFeatureKey = 'auth';

export interface State extends EntityState<User> {
  refreshingToken: boolean;
  loggingIn: boolean;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({
  loggingIn: false,
  refreshingToken: false,
});

export const reducer = createReducer<State>(
  initialState,

  // Login
  on(AuthActions.login, (state) =>
    produce(state, (draft) => {
      draft.loggingIn = true;
    }),
  ),
  on(AuthActions.loginSuccess, (state, { data: { user } }) => ({
    ...adapter.upsertOne(user, state),
    loggingIn: false,
  })),
  on(AuthActions.loginFailure, (state) =>
    produce(state, (draft) => {
      draft.loggingIn = false;
    }),
  ),

  // Logout
  on(AuthActions.logout, (state) => state),
  on(AuthActions.logoutSuccess, () => initialState),
  on(AuthActions.logoutFailure, () => initialState),

  // Refresh Token
  on(AuthActions.refreshToken, (state) =>
    produce(state, (draft) => {
      draft.refreshingToken = true;
    }),
  ),
  on(AuthActions.refreshTokenSuccess, (state, { data: { user } }) => ({
    ...adapter.upsertOne(user, state),
    refreshingToken: false,
  })),
  on(AuthActions.refreshTokenFailure, (state) =>
    produce(state, (draft) => {
      draft.refreshingToken = false;
    }),
  ),
);
