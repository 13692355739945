import { Component, Input } from '@angular/core';
import { UnprocessableEntityErrors } from '../../utils/types';

@Component({
  selector: 'tec-form-error-card',
  templateUrl: './tec-form-error-card.component.html',
})
export class TecFormErrorCardComponent {
  @Input()
  public errors?: UnprocessableEntityErrors;

  public get messages(): string[] {
    return Object.values(this.errors || {}).flat();
  }
}
