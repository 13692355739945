import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { NO_ERROR_HANDLER } from '../http-contexts';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandler: ErrorHandler) {}
  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.context.has(NO_ERROR_HANDLER)) return next.handle(req);
    return next.handle(req).pipe(
      catchError((err) => {
        this.errorHandler.handleError(err);
        return throwError(() => err);
      }),
    );
  }
}
