import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tec-dialog-form-actions',
  templateUrl: './tec-dialog-form-actions.component.html',
  styleUrls: ['./tec-dialog-form-actions.component.scss'],
})
export class TecDialogFormActionsComponent {
  @Input()
  public submitLabel = 'actions.submit';
  @Input()
  public cancelLabel = 'actions.cancel';
  @Output()
  public submitAction = new EventEmitter<MouseEvent>();
  @Output()
  public cancelAction = new EventEmitter<MouseEvent>();
}
