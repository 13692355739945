import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//MAtERIAL
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
//NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// REDUX
import { routerFeatureKey } from '@store/router';
import { reducers, metaReducers, APP_EFFECTS } from '@store';
import { environment } from '../environments/environment';

import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared';

import { CoreModule } from './core/core.module';
import { TecLoadingModule } from './shared/components/tec-loading/tec-loading.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PACKAGE_NAME } from './shared/utils/constant';

const NGRX_IMPORTS = [
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {
      strictActionImmutability: true,
      strictStateImmutability: true,
    },
  }),
  StoreRouterConnectingModule.forRoot({
    stateKey: routerFeatureKey,
    routerState: RouterState.Minimal,
  }),
  EffectsModule.forRoot(APP_EFFECTS),
  StoreDevtoolsModule.instrument({
    name: PACKAGE_NAME,
    logOnly: !environment.production,
  connectInZone: true}),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    CoreModule,
    TecLoadingModule,
    ...NGRX_IMPORTS,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
