import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'src/app/core/classes';

@Directive({
  selector: '[tecNoDataRow]',
})
export class TecNoDataRowDirective {
  private readonly logger = new Logger(TecNoDataRowDirective.name);

  @Input({ required: true })
  public set displayedColumns(val: string[]) {
    this.handleDisplayColumnsChange(val);
  }
  /**
   * Array containing the names of the columns displayed in the table
   */
  public get displayedColumns(): string[] {
    return this._displayedColumns;
  }
  private _displayedColumns: string[] = [];

  @Input()
  public set isLoading(val: boolean) {
    this.handleIsLoadingChange(val);
  }

  /**
   * Determines whether the loading progress bar will be displayed.
   */
  public get isLoading(): boolean {
    return this._isLoading;
  }

  private _isLoading = false;
  private td?: HTMLTableCellElement;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
  ) {
    this.verifyElementRefNodeName();
    this.applyMatRowClassName();
    this.createTd();
  }

  public verifyElementRefNodeName(): void {
    if (this.elementRef.nativeElement.nodeName !== 'TR') {
      this.logger.warn(
        'This directive should be applied to an HTMLTableRowElement <tr>',
      );
    }
  }

  public applyMatRowClassName(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'mat-row');
  }

  public createTd(): void {
    this.td = this.renderer.createElement('td');
    this.renderer.addClass(this.td, 'mat-cell');
    this.renderer.appendChild(this.elementRef.nativeElement, this.td);
  }

  public handleDisplayColumnsChange(newValue: string[]): void {
    this._displayedColumns = newValue;
    if (this.td) {
      this.renderer.setAttribute(this.td, 'colspan', `${newValue.length}`);
    }
  }

  public handleIsLoadingChange(newValue: boolean): void {
    this._isLoading = newValue;
    if (newValue) {
      this.showLoading();
    } else {
      this.showNoDataMessage();
    }
  }

  public showLoading(): void {
    if (this.td) {
      this.td.textContent = '';
      this.renderer.setStyle(this.td, 'padding', '0');
      const component = this.viewContainerRef.createComponent(MatProgressBar);
      component.instance.mode = 'indeterminate';
      this.renderer.appendChild(this.td, component.location.nativeElement);
    }
  }

  public showNoDataMessage(): void {
    if (this.td) {
      this.td.textContent = this.translateService.instant('table.no-data');
      this.renderer.setStyle(this.td, 'padding', '1rem');
    }
  }
}
