import { InjectionToken, Provider } from '@angular/core';
import { PACKAGE_NAME, PACKAGE_VERSION } from '@shared/utils';

export const PACKAGE = new InjectionToken('PACKAGE');
export const PACKAGE_PROVIDER: Provider = {
  provide: PACKAGE,
  useValue: {
    name: PACKAGE_NAME,
    version: PACKAGE_VERSION,
  },
};
