import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TecPageHeaderComponent } from './tec-page-header.component';
import { PipesModule } from '../../pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { CommonMaterialModule } from '../../modules/common-material.module';

@NgModule({
  declarations: [TecPageHeaderComponent],
  imports: [CommonModule, PipesModule, CommonMaterialModule, RouterModule],
  exports: [TecPageHeaderComponent],
})
export class TecPageHeaderModule {}
