import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme/theme.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_LANG_KEY } from './shared/utils/constant';
import { Language } from './shared/utils/enums';
import { LoadingService } from './core/services/loading/loading.service';

@Component({
  selector: 'app-root',
  template: `<tec-loading [loading]="isLoading"></tec-loading>
    <router-outlet></router-outlet> `,
})
export class AppComponent {
  public isLoading = false;
  constructor(
    private themeService: ThemeService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
  ) {
    this.handleLoading();
    this.loadAppLanguages();
    this.loadAppTheme();
  }

  public handleLoading(): void {
    this.loadingService.isLoading$.subscribe({
      next: (value) => {
        setTimeout(() => {
          this.isLoading = value;
        });
      },
    });
  }

  public loadAppTheme(): void {
    this.themeService.loadTheme();
  }

  public loadAppLanguages(): void {
    const lsLanguage = localStorage.getItem(LOCAL_STORAGE_LANG_KEY);
    const availableLanguages = [Language.ES, Language.EN];
    this.translateService.addLangs(availableLanguages);
    this.translateService.setDefaultLang(Language.ES);
    this.translateService.use(lsLanguage || this.translateService.defaultLang);

    this.translateService.onLangChange.subscribe({
      next: (ev: LangChangeEvent) => {
        localStorage.setItem(LOCAL_STORAGE_LANG_KEY, ev.lang);
      },
    });
  }
}
