import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  public getBase64(file: File): Promise<string> {
    return new Promise<string>((res, rej) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          res(reader.result);
        } else {
          rej('Could not get Base64!');
        }
      };
      reader.readAsDataURL(file);
      reader.onerror = (err) => rej(err);
    });
  }
}
