import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TecThemeSwitcherComponent } from './tec-theme-switcher.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [TecThemeSwitcherComponent],
  imports: [CommonModule, MatIconModule, MatSlideToggleModule],
  exports: [TecThemeSwitcherComponent],
})
export class TecThemeSwitcherModule {}
