import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export declare type ToastSeverity = 'error' | 'warning' | 'info' | 'success';
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private snackBarDefaultConfig: MatSnackBarConfig = {
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    duration: 90000,
    panelClass: ['severity-container'],
  };

  constructor(
    private snackbar: MatSnackBar,
    private ngZone: NgZone,
    private translate: TranslateService,
  ) {}

  public showSuccess(
    message?: string,
    action?: string,
    config: MatSnackBarConfig = {},
  ): void {
    this.showToast(
      message || this.translate.instant('common.notifications.done'),
      'success',
      action,
      config,
    );
  }

  public showInfo(
    message: string,
    action?: string,
    config: MatSnackBarConfig = {},
  ): void {
    this.showToast(message, 'info', action, config);
  }

  public showWarn(
    message: string,
    action?: string,
    config: MatSnackBarConfig = {},
  ): void {
    this.showToast(message, 'warning', action, config);
  }

  public showError(
    message: string,
    action?: string,
    config: MatSnackBarConfig = {},
  ): void {
    this.showToast(message, 'error', action, config);
  }

  public showToast(
    message: string,
    severity: ToastSeverity,
    action = 'Ok',
    config: MatSnackBarConfig = {},
  ): void {
    this.ngZone.run(() => {
      this.snackbar.open(message, action, {
        ...this.snackBarDefaultConfig,
        ...config,
        panelClass: [
          ...(this.snackBarDefaultConfig.panelClass || []),
          'app-toast',
          'severity-' + severity,
        ],
      });
    });
  }

  public showUpdated(config: MatSnackBarConfig = {}): void {
    this.showSuccess(
      this.translate.instant('common.notifications.updated'),
      undefined,
      config,
    );
  }

  public showDeleted(config: MatSnackBarConfig = {}): void {
    this.showSuccess(
      this.translate.instant('common.notifications.deleted'),
      undefined,
      config,
    );
  }

  public showAdded(config: MatSnackBarConfig = {}): void {
    this.showSuccess(
      this.translate.instant('common.notifications.added'),
      undefined,
      config,
    );
  }

  public showEmailSended(config: MatSnackBarConfig = {}): void {
    this.showSuccess(
      this.translate.instant('common.notifications.email-sended'),
      undefined,
      config,
    );
  }
}
