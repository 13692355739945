import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonMaterialModule } from './modules/common-material.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TecConfirmDialogModule } from './components/tec-confirm-dialog/tec-confirm-dialog.module';
import { TecTableToolbarModule } from './components/tec-table-toolbar/tec-table-toolbar.module';
import { TecThemeSwitcherModule } from './components/tec-theme-switcher/tec-theme-switcher.module';
import { PipesModule } from './pipes/pipes.module';
import { TecPageHeaderModule } from './components/tec-page-header/tec-page-header.module';
import { TecLangSelectorModule } from './components/tec-lang-selector/tec-lang-selector.module';
import { TecDialogFormActionsModule } from './components/tec-dialog-form-actions/tec-dialog-form-actions.module';
import { DirectivesModule } from './directives/directives.module';
import { FormErrorCardModule } from './components/tec-form-error-card/tec-form-error-card.module';
import { TecFileInputModule } from './components/tec-file-input/tec-file-input.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonMaterialModule,
    AngularEditorModule,
  ],
  exports: [
    AngularEditorModule,
    TecConfirmDialogModule,
    CommonMaterialModule,
    TecPageHeaderModule,
    DirectivesModule,
    PipesModule,
    ReactiveFormsModule,
    TecTableToolbarModule,
    TecThemeSwitcherModule,
    TecLangSelectorModule,
    FormErrorCardModule,
    TecDialogFormActionsModule,
    TecFileInputModule,
  ],
})
export class SharedModule {}
