import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Nullable } from '../../utils/types';
import { ValueMustMatchValidationError } from 'src/app/core/classes/value-must-match-validation-error.class';
import {
  MaxFileSizeExceededValidationError,
  PasswordMustMatchValidationError,
} from 'src/app/core/classes';
import { NoSpecialCharactersValidationError } from 'src/app/core/classes/no-special-characters-validation-error.class';
import { OnlyNumericValidationError } from 'src/app/core/classes/only-numeric-validation-error.class';
import { OnlyAlphabeticValidationError } from 'src/app/core/classes/only-alphabetic-validation-error.class';
import { UniqueEmailValidationError } from 'src/app/core/classes/email-already-registered-validation-error.class';
import { EmailValidationError } from 'src/app/core/classes/email-validation-error.class';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[tecControlErrors]',
})
export class ControlErrorsDirective implements OnInit, OnChanges, OnDestroy {
  private _tecControlErrors?: Nullable<ValidationErrors> = null;
  public errorText = '';
  @Input()
  public set tecControlErrors(errors: Nullable<ValidationErrors> | undefined) {
    this._tecControlErrors = errors;
  }

  private langChangeSubs?: Subscription;

  constructor(
    private elementRef: ElementRef,
    private translate: TranslateService,
  ) {}
  public ngOnInit(): void {
    this.langChangeSubs = this.translate.onLangChange.subscribe(() => {
      this.writeErrors();
    });
  }
  public ngOnDestroy(): void {
    this.langChangeSubs?.unsubscribe();
  }

  public ngOnChanges(): void {
    this.writeErrors();
  }

  public writeErrors(): void {
    this.errorText = '';

    if (this._tecControlErrors) {
      const nativeErrors: Record<string, string> = {
        required: 'control-error-message.required',
        maxlength: 'control-error-message.maxlength',
        minlength: 'control-error-message.minlength',
        max: 'control-error-message.max.',
        min: 'control-error-message.min.',
        pattern: 'control-error-message.pattern',
        email: 'control-error-message.email',
      };

      const customErrors: Record<string, string> = {
        [EmailValidationError.name]: 'control-error-message.email',
        [UniqueEmailValidationError.name]: 'control-error-message.unique-email',
        [OnlyAlphabeticValidationError.name]:
          'control-error-message.only-alphabetic',
        [OnlyNumericValidationError.name]: 'control-error-message.only-numeric',
        [NoSpecialCharactersValidationError.name]:
          'control-error-message.no-special-characters',
        [PasswordMustMatchValidationError.name]:
          'control-error-message.passwords-must-match',
        [MaxFileSizeExceededValidationError.name]:
          'control-error-message.max-file-size-exceeded',
        [ValueMustMatchValidationError.name]:
          'control-error-message.value-must-match',
      };

      Object.keys(this._tecControlErrors).forEach((key, i, array) => {
        this.errorText += key?.startsWith('error__')
          ? this._tecControlErrors
            ? this._tecControlErrors['value']
            : ''
          : this.translate.instant(
              nativeErrors[key] ||
                customErrors[key] ||
                'control-error-message.invalid-field',
              this._tecControlErrors ? this._tecControlErrors[key] : '',
            );
        this.errorText += i + 1 < array.length ? ' ' : '';
      });
      this.elementRef.nativeElement.textContent = this.errorText;
    }
  }
}
